var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('paged-table-view',{attrs:{"query-form":_vm.queryForm,"search-function":_vm.fetchData,"index-route-name":"foundation"},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":_vm.$l.getLanguageText('financeSaleStatistic.orderCode')},model:{value:(_vm.queryForm.orderCode),callback:function ($$v) {_vm.$set(_vm.queryForm, "orderCode", $$v)},expression:"queryForm.orderCode"}})],1),_c('el-form-item',[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$l.getLanguageText('orderManagement.status'),"clearable":"","filterable":""},model:{value:(_vm.queryForm.status),callback:function ($$v) {_vm.$set(_vm.queryForm, "status", $$v)},expression:"queryForm.status"}},_vm._l((_vm.orderStatusList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.text,"value":item.value}})}),1)],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('el-divider',{staticClass:"query-form-divider"}),_c('export-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.Finance.SaleStatistic.Export'),expression:"'Pages.Finance.SaleStatistic.Export'"}],attrs:{"url":"/api/services/app/FinanceSalesStatistic/Export","file-title":_vm.$l
            .getLanguageText('financeSaleStatistic.salesStatistics')
            .toString(),"query-model":_vm.queryForm,"buttonTitle":_vm.$l.getLanguageText('basic.export').toString()}})]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('financeSaleStatistic.index').toString(),"type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('financeSaleStatistic.orderCode').toString(),"prop":"fkOrder.orderCode"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('financeSaleStatistic.customerInfo').toString()},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.fkOrder && scope.row.fkOrder.fkCustomer ? ((scope.row.fkOrder.fkCustomer.enterpriseName) + " - " + (scope.row.fkOrder.fkCustomer.customerCode)) : "")+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('financeSaleStatistic.totalAmount').toString(),"prop":"fkOrder.totalAmount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")((row.fkOrder ? row.fkOrder.totalAmount : 0)))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('financeSaleStatistic.status').toString(),"prop":"fkOrder.status"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getStatusText(scope.row.fkOrder.status))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('warehouseExManagement.exwarehouse'),"align":"center","prop":"fkOrder.fkWarehouse.name"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('financeSaleStatistic.creationTime').toString(),"prop":"fkOrder.creationTime"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("formatYearMonthDayDate")(scope.row.fkOrder.creationTime))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('financeSaleStatistic.paidAmount').toString(),"prop":"paidAmount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.paidAmount))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('financeSaleStatistic.arrearsAmount').toString(),"prop":"arrearsAmount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.arrearsAmount))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l
            .getLanguageText('financeSaleStatistic.repaymentDeadline')
            .toString(),"prop":"repaymentDeadline"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("formatYearMonthDayDate")(scope.row.repaymentDeadline))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('financeSaleStatistic.operation').toString(),"class-name":"operate-column"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleInvoiceDetail(scope.$index, scope.row)}}},[_vm._v(_vm._s(_vm.$l.getLanguageText("order.invoiceDetail"))+" ")])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }