



























































































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  FinanceSalesStatisticDto,
  OrderStatus,
  SelectListItem,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import AbSelect from "@/components/AbSelect.vue";
import ExportButton from "@/components/ExportButton.vue";

@Component({
  components: {
    PagedTableView,
    ExportButton,
    AbSelect,
  },
})
export default class Enterprise extends Vue {
  editId = 0;
  queryForm = {
    orderCode: "",
    status: "",
  };

  orderStatusList = [] as SelectListItem[];

  created() {
    this.fetchOrderStatusList();
  }

  fetchData(params: never) {
    return api.financeSaleStatistic.getAll(params);
  }

  async fetchOrderStatusList() {
    await api.enumService.getValues({ typeName: "OrderStatus" }).then((res) => {
      this.orderStatusList = res;
    });
  }
  getStatusText(value: any) {
    let text = "";
    for (let i in this.orderStatusList) {
      if (this.orderStatusList[i].value === value) {
        text = this.orderStatusList[i].text!;
      }
    }
    return text;
  }
  // 查看发票
  handleInvoiceDetail(index: number, row: FinanceSalesStatisticDto) {
    this.$router.push({
      name: "invoiceDetail",
      params: { id: row.orderId! + "" },
    });
  }
}
